import React, { useContext, useState } from "react";
import { Section8Context } from "../context/section8.context";
import { Section3Context } from "../context/section3.context";
import { ContactFormContext } from "../context/contactForm.context";
import { Section6Context } from "../context/section6.context";

const Logo = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuClosing, setIsMenuClosing] = useState(false);
  const { setShowContactPage } = useContext(Section8Context);
  const { showPlayButtons } = useContext(Section3Context);
  const { setShowContactUsModal, showContactUsModal } =
    useContext(ContactFormContext);

  const { showBlueLogo } = useContext(Section6Context);

  const handleLogoClick = () => {
    setIsMenuOpen(true);
    setIsMenuClosing(false);
  };

  const handleContactClick = () => {
    setShowContactPage(true);
    setIsMenuOpen(false);
    setIsMenuClosing(true);
  };

  return (
    <>
      <div className="relative z-20 ">
        <div className="fixed top-8 sm:top-10 left-0 z-50 w-[60px] sm:w-[70px]  lg:w-[100px] h-auto ">
          <img
            src={`${
              showBlueLogo
                ? "assets/img/Logo_Alpha Dark Blue.svg"
                : "assets/img/Logo_Alpha.png"
            }`}
            alt="Logo"
          />
          {/* <p id="addcontent" className="text-white text-sm">
            test
          </p> */}
        </div>

        {!isMenuOpen && !showContactUsModal ? (
          <div
            className={`fixed top-12 sm:top-20 right-6 2xl:right-24 z-50 cursor-pointer  ${
              isMenuClosing && "fadeIn"
            } ${!showPlayButtons ? "block fadeIn" : "hidden "}`}
            onClick={handleLogoClick}
          >
            <div className=" space-y-1 lg:space-y-2 right-10">
              <div
                className={`w-6 sm:w-6 lg:w-10 h-0.5 ${
                  showBlueLogo ? "bg-[#32417A]" : "bg-white"
                }`}
              ></div>
              <div
                className={`w-6 sm:w-6 lg:w-10 h-0.5 ${
                  showBlueLogo ? "bg-[#32417A]" : "bg-white"
                }`}
              ></div>
              <div
                className={`w-6 sm:w-6 lg:w-10 h-0.5 ${
                  showBlueLogo ? "bg-[#32417A]" : "bg-white"
                }`}
              ></div>
            </div>
          </div>
        ) : (
          <button
            onClick={() => {
              setIsMenuClosing(true);
              setTimeout(() => {
                setIsMenuOpen(false);
              }, 900);
            }}
            className={`pointer-events-auto fixed top-10 lg:top-20 right-10   w-10 h-10 sm:w-10 sm:h-10  lg:w-12 lg:h-12 border-2 border-solid border-white uppercase text-center text-lg leading-[1.2] text-white rounded-full flex items-center justify-center active:scale-100 hover:shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75] transition-all  z-20 ${
              isMenuOpen && "blur-animate"
            } ${isMenuClosing && "fadeout-animate--1"}`}
          >
            <span className="w-5 sm:w-6 lg:w-7 h-0.5 bg-white inline-block absolute -rotate-45" />
            <span className="w-5 sm:w-6 lg:w-7 h-0.5 bg-white inline-block absolute rotate-45" />
          </button>
        )}

        {isMenuOpen && (
          <section
            className={`main_menu absolute w-full z-10 top-0 h-[100dvh] bg-[#53535378] backdrop-blur-[40px] backdrop-saturate-[180%]
          ${isMenuOpen && "blur-animate"} ${
              isMenuClosing && "fadeout-animate--1"
            }`}
          >
            <div className="menu_btn relative text-center h-full w-full flex flex-col justify-center items-center gap-16 ">
              <button
                onClick={() => {
                  window.location.href =
                    "https://catalogue.intodalai.com/3d-catalogue/";
                }}
                className="catalog_btn block leading-1 uppercase border rounded-md divide-white text-base text-white p-5 w-[16rem] sm:!w-[23rem] active:scale-100 hover:shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75] transition-all"
              >
                <span className="">3D Catalogue</span>
              </button>
              <button
                onClick={handleContactClick}
                className="contact_btn block leading-1 uppercase border rounded-md divide-white text-base text-white p-5 w-[16rem] sm:!w-[23rem] active:scale-100 hover:shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75] transition-all"
              >
                <span className="">Contact Us</span>
              </button>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default Logo;
