import React, { useContext, useEffect, useRef, useState } from "react";
import PngLoader from "../pngLoader.component";
import TextComponent from "../text.component";
import Particles from "./particles.component";
import { useFrame, useThree } from "@react-three/fiber";
import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";
import { useScroll } from "../ScrollControls.js";
import { LoadingContext } from "../../context/loading.context.js";
import MovingTextComponent from "../movingText.component.js";
import RandomSizeParticlesSection5 from "../section5/particles.component.js";

const Section1 = () => {
  const { width } = useThree((state) => state.viewport);
  const [dalaiImageScale, setDalaiImageScale] = useState();
  const [textAnimationScale, setTextAnimationScale] = useState();
  const [textAnimationPosition, setTextAnimationPosition] = useState();
  const [dalaiImagePosition, setDalaiImagePosition] = useState();
  const [buttonPosition, setButtonPosition] = useState();
  const [buttonScale, setButtonScale] = useState();
  const [reachedSection1, setReachedSection1] = useState(false);
  const [plantImagePosition, setPlantImagePosition] = useState();
  const [plantImageScale, setPlantImageScale] = useState();
  const { setLoading } = useContext(LoadingContext);
  const prevScrollRef = useRef(null);
  var userAgent = navigator.userAgent;

  const { scroll, el } = useScroll();
  useEffect(() => {
    if (width < 5.27) {
      setDalaiImagePosition([0, -0.1]);
      setDalaiImageScale(1.1);
      setTextAnimationScale(width * 0.00061);
      setTextAnimationPosition([-width * 0.18, -0.5, 2.4]);
      setButtonPosition([-0, -0.8]);
      setButtonScale([0.062, 0.5]);
      setPlantImagePosition([-0.06, -0.25, -0.6]);
      setPlantImageScale([2.65, 2.9, 1]);
    } else if (width < 7.7 && width >= 5.27) {
      setDalaiImagePosition([0, -0.4]);
      setDalaiImageScale(width * 0.22);
      setTextAnimationScale(width * 0.00058);
      setTextAnimationPosition([-width * 0.17, -1, 2.4]);
      setButtonPosition([-0, -1.3]);
      setButtonScale([0.07, 0.5]);
      setPlantImagePosition([-0.12, -0.35, -0.6]);
      setPlantImageScale([3, 3, 1]);
    } else if (width >= 7.7 && width < 11.34) {
      setDalaiImagePosition([0, -0.25]);
      setDalaiImageScale(width * 0.18);
      setTextAnimationScale(width * 0.00051);
      setTextAnimationPosition([-width * 0.15, -1.15, 2.4]);
      setButtonPosition([0, -1.4]);
      setButtonScale([0.08, 0.5]);
      setPlantImagePosition([-0.2, -0.2, -0.6]);
      setPlantImageScale([3.2, 3.4, 1]);
    } else if (width >= 11.34 && width <= 14.5) {
      setDalaiImagePosition([0, -0.35]);
      setDalaiImageScale(width * 0.14);
      setTextAnimationScale(width * 0.00043);
      setTextAnimationPosition([-width * 0.13, -1.2, 2.4]);
      setButtonPosition([0, -1.55]);
      setButtonScale([0.09, 0.6]);
      setPlantImagePosition([-0.2, -0.2, -0.6]);
      setPlantImageScale([3.2, 3.4, 1]);
    } else {
      setDalaiImagePosition([-0.15, -0.1]);
      setDalaiImageScale(width * 0.125);
      setTextAnimationPosition([-width * 0.13, -1.1, 2.4]);
      setTextAnimationScale(width * 0.00043);
      setButtonPosition([-0.1, -1.4]);
      setButtonScale([0.09, 0.6]);
      setPlantImagePosition([-0.2, -0.2, -0.6]);
      setPlantImageScale([3.2, 3.4, 1]);
    }
  }, [width]);

  useEffect(() => {
    if (reachedSection1) {
      setLoading("section1Reached", true);
    }
  }, [reachedSection1]);

  useEffect(() => {
    if (userAgent.match(/iPhone/i)) {
      setScrollTopToPercentage(1.5, el);
      scroll.current = 1.5 / 100;
    }
  }, []);

  useFrame(() => {
    const currentScroll = scroll.current * 100;

    if (currentScroll <= 0 && !userAgent.match(/iPhone/i)) {
      setScrollTopToPercentage(1.6, el);
      scroll.current = 1.6 / 100;
    }

    if (currentScroll >= 1.2 && currentScroll <= 1.6) {
      setReachedSection1(true);
    }

    if (currentScroll > 99.99) {
      setReachedSection1(false);
    }

    // section 1 exit
    if (currentScroll >= 3.5 && currentScroll < 14.6) {
      scroll.current -= 0.18 / 100;
    }
    if (currentScroll > 3.2 && currentScroll < 3.51) {
      setScrollTopToPercentage(3.5, el);
    }

    // section 2 entry
    if (currentScroll >= 14.6 && currentScroll < 36) {
      scroll.current += 0.18 / 100;
      if (currentScroll > 35.3 && currentScroll < 36) {
        setScrollTopToPercentage(36, el);
      }
    }

    prevScrollRef.current = scroll.current;
  });

  return (
    <>
      <group>
        {dalaiImageScale && dalaiImagePosition && (
          <>
            <mesh
              position={[dalaiImagePosition[0], dalaiImagePosition[1], 2.4]}
            >
              <PngLoader
                renderOrder={-10}
                transparent={true}
                path="assets/img/dalai logo.png"
                scale={[dalaiImageScale, dalaiImageScale, 1]}
              />
            </mesh>
            <mesh position={[buttonPosition[0], buttonPosition[1], 2.4]}></mesh>
            <mesh
              renderOrder={-10}
              position={[buttonPosition[0], buttonPosition[1], 2.4]}
              onClick={() => {
                window.location.href = "https://catalogue.intodalai.com/";
              }}
            >
              <mesh
                onPointerOver={() => (document.body.style.cursor = "pointer")}
                onPointerLeave={() => (document.body.style.cursor = "auto")}
                scale={[buttonScale[0], buttonScale[1], 1.5]}
              >
                <PngLoader
                  renderOrder={-1}
                  transparent={true}
                  path="assets/img/EXPLORE 3D CATALOGUE - Semi Bold 4X.png"
                  scale={[4.1, 0.65, 0.5]}
                />
              </mesh>
            </mesh>
          </>
        )}

        {/* <Particles
          size={150}
          position={[0, -1, -1.4]}
          randomSizeOfPoints={[
            { particlesCount: 2200, pointSize: 0.0248 },
            { particlesCount: 1800, pointSize: 0.0148 },
            { particlesCount: 800, pointSize: 0.0348 },
          ]}
          yHeight={-1.6}
        />

        <RandomSizeParticlesSection5 />

        <Particles
          size={200}
          position={[0, -16, -0.4]}
          randomSizeOfPoints={[
            { particlesCount: 2200, pointSize: 0.02 },
            { particlesCount: 3800, pointSize: 0.0148 },
            { particlesCount: 3800, pointSize: 0.01 },
          ]}
          yHeight={-1.4}
        /> */}

        <PngLoader
          transparent={true}
          renderOrder={-100}
          path="assets/img/Plant-Image.png"
          position={plantImagePosition}
          scale={plantImageScale}
        />
        <PngLoader
          transparent={true}
          renderOrder={-100}
          path="assets/img/Section-1-Image.png"
          position={[0, -0.3, -1]}
          scale={[5, 4, 1]}
        />
        {textAnimationPosition && (
          <TextComponent
            text={`BRIDGING CBD INDUSTRY`}
            align={"center"}
            particlesCount={100}
            scale={[textAnimationScale, -textAnimationScale, 0.02]}
            position={textAnimationPosition}
            pointSize={2.0}
          />
        )}
      </group>
      <MovingTextComponent />
      <group position={[0, -15.3, 0]}>
        {dalaiImageScale && dalaiImagePosition && (
          <>
            <mesh
              position={[dalaiImagePosition[0], dalaiImagePosition[1], 2.4]}
            >
              <PngLoader
                renderOrder={-10}
                transparent={true}
                path="assets/img/dalai logo.png"
                scale={[dalaiImageScale, dalaiImageScale, 1]}
              />
            </mesh>
            <mesh position={[buttonPosition[0], buttonPosition[1], 2.4]}></mesh>
            <mesh
              renderOrder={-10}
              position={[buttonPosition[0], buttonPosition[1], 2.4]}
              onClick={() => {
                window.location.href = "https://catalogue.intodalai.com/";
              }}
            >
              <mesh
                onPointerOver={() => (document.body.style.cursor = "pointer")}
                onPointerLeave={() => (document.body.style.cursor = "auto")}
                scale={[buttonScale[0], buttonScale[1], 1.5]}
              >
                <PngLoader
                  renderOrder={-11}
                  transparent={true}
                  path="assets/img/EXPLORE 3D CATALOGUE - Semi Bold 4X.png"
                  scale={[4.1, 0.65, 0.5]}
                />
              </mesh>
            </mesh>
          </>
        )}

        <PngLoader
          transparent={true}
          renderOrder={-100}
          path="assets/img/Plant-Image.png"
          position={plantImagePosition}
          scale={plantImageScale}
          // zoom={0.9}
        />
        <PngLoader
          transparent={true}
          renderOrder={-100}
          path="assets/img/Section-1-Image.png"
          position={[0, -0.3, -1]}
          scale={[5, 4, 1]}
        />

        {textAnimationPosition && (
          <TextComponent
            text={`BRIDGING CBD INDUSTRY`}
            align={"center"}
            particlesCount={100}
            pointSize={2.0}
            scale={[textAnimationScale, -textAnimationScale, 0.02]}
            position={textAnimationPosition}
          />
        )}
      </group>
    </>
  );
};

export default Section1;
