import React, { createContext, useState } from "react";

const defaultValues = {
  showBlueLogo: false,
  setShowBlueLogo: () => {},
  autoRotateCarousel: false,
  setAutoRotateCarousel: () => {},
};
export const Section6Context = createContext(defaultValues);

export const Section6ContextProvider = ({ children }) => {
  const [showBlueLogo, setShowBlueLogo] = useState(false);
  const [autoRotateCarousel, setAutoRotateCarousel] = useState(false);
  const value = {
    showBlueLogo,
    setShowBlueLogo,
    autoRotateCarousel,
    setAutoRotateCarousel,
  };

  return (
    <Section6Context.Provider value={value}>
      {children}
    </Section6Context.Provider>
  );
};
