import { useFrame, useThree } from "@react-three/fiber";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { Shape, SpotLight, SpotLightHelper } from "three";
import { LoadingContext } from "../../context/loading.context";

const MariaModel = ({ gltf, scale, position, animate, showLight }) => {
  const { setLoading } = useContext(LoadingContext);
  const [rotateLeft, setRotateLeft] = useState(true);
  const [isSpotLightAdded, setIsSpotLightAdded] = useState(false);
  const rotationRef = useRef(0);
  const modelRef = useRef();
  const sphereRef = useRef();
  const { scene } = useThree();
  gltf.scale.set(...scale);
  gltf.renderOrder = 1;
  gltf.traverse(function (node) {
    if (node.isMesh || node.isLight) node.castShadow = true;
    if (node.isMesh || node.isLight) node.receiveShadow = true;
  });

  const positiveRotation = [
    0.00001, 0.00002, 0.00003, 0.00004, 0.00005, 0.00006, 0.00007, 0.00008,
    0.00009,
  ];

  useEffect(() => {
    if (gltf) {
      setLoading("section3Maria", true);
    }
  }, [gltf, setLoading]);

  useEffect(() => {
    if (gltf && showLight && !isSpotLightAdded) {
      const sphereGeometry = new THREE.SphereGeometry(0.8, 20, 20);

      const holeShape = new Shape();
      holeShape.moveTo(0, 0);
      holeShape.absarc(0, 0, 0.1, 0, Math.PI * 2, false);

      // Extrude the hole shape
      const sphereBufferGeometry = new THREE.BufferGeometry().copy(
        sphereGeometry
      );

      const combinedAttributes = {
        position: [...sphereBufferGeometry.attributes.position.array],
        normal: [...sphereBufferGeometry.attributes.normal.array],
        uv: [...sphereBufferGeometry.attributes.uv.array],
      };

      const mergedGeometry = new THREE.BufferGeometry();
      mergedGeometry.setAttribute(
        "position",
        new THREE.BufferAttribute(
          new Float32Array(combinedAttributes.position),
          3
        )
      );
      mergedGeometry.setAttribute(
        "normal",
        new THREE.BufferAttribute(
          new Float32Array(combinedAttributes.normal),
          3
        )
      );
      mergedGeometry.setAttribute(
        "uv",
        new THREE.BufferAttribute(new Float32Array(combinedAttributes.uv), 2)
      );

      const sphereWithHoles = new THREE.Mesh(mergedGeometry);

      sphereWithHoles.castShadow = true;
      sphereWithHoles.receiveShadow = true;

      gltf.add(sphereWithHoles);
      sphereRef.current = sphereWithHoles;

      gltf.traverse(function (node) {
        if (node.isMesh || node.isLight) node.castShadow = true;
        if (node.isMesh || node.isLight) node.receiveShadow = true;
      });

      const whitePointLight = new SpotLight(0xffffff, 20);
      whitePointLight.castShadow = true;
      whitePointLight.angle = Math.PI / 2.5; // Adjust the angle to cover the sphere
      whitePointLight.penumbra = 0;
      whitePointLight.decay = 1;
      whitePointLight.distance = 150;

      whitePointLight.shadow.camera.near = 0.5;
      whitePointLight.shadow.camera.far = 1000;
      whitePointLight.shadow.camera.fov = 30;

      // Set the position of the SpotLight above the sphere
      sphereWithHoles.position.set(6, 4, 6);
      whitePointLight.position.set(6, 4, 6);
      whitePointLight.target.position.set(0, -27, 2);
      // Add the SpotLight to the scene
      gltf.add(whitePointLight);

      // Add the SpotLightHelper for visualization
      const spotLightHelper = new SpotLightHelper(whitePointLight);

      spotLightHelper.cone.scale.set(3, 3, 3);
      scene.add(spotLightHelper);
      setIsSpotLightAdded(true);
    }
  }, [gltf, showLight, position]);

  useFrame(() => {
    if (sphereRef.current) {
      const vertices = sphereRef.current.geometry.attributes.position.array;
      const speed = 0.005;

      // Apply rotation to the vertices
      for (let i = 0; i < vertices.length; i += 3) {
        const x = vertices[i];
        const y = vertices[i + 1];
        const z = vertices[i + 2];

        // Rotate around the y-axis
        const newX = x * Math.cos(speed) - z * Math.sin(speed);
        const newZ = x * Math.sin(speed) + z * Math.cos(speed);

        vertices[i] = newX;
        vertices[i + 2] = newZ;
      }

      // Update the BufferGeometry
      sphereRef.current.geometry.attributes.position.needsUpdate = true;

      if (modelRef.current) {
        const newSpeed =
          positiveRotation[
            Math.floor(Math.random() * (positiveRotation.length - 1))
          ];
        if (rotationRef.current + newSpeed > 0.007 && rotateLeft) {
          setRotateLeft(false);
        } else if (rotationRef.current - newSpeed < -0.007 && !rotateLeft) {
          setRotateLeft(true);
        }

        if (rotateLeft) {
          rotationRef.current += newSpeed;
        } else {
          rotationRef.current -= newSpeed;
        }

        modelRef.current.rotation.x = rotationRef.current;
      }
    }
  });

  return (
    <primitive
      ref={modelRef}
      object={animate ? gltf : gltf.clone(true)}
      position={position}
      castShadow
      receiveShadow
    />
  );
};

export default MariaModel;
