import { useFrame } from "@react-three/fiber";
import PngLoader from "../pngLoader.component";
import { useScroll } from "../ScrollControls";
import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";
import { useContext, useRef } from "react";
import { Section8Context } from "../../context/section8.context";
import { ScrollContext } from "../../context/scroll.context";
import { ContactFormContext } from "../../context/contactForm.context";

const Section5Box = ({
  isPacketInDestination,
  direction,
  boxScale,
  boxPosition,
  emailImagePosition,
  instaImagePosition,
  mobileImagePosition,
  emailImageScale,
  instaImageScale,
  mobileImageScale,
  contactButtonPosition,
  contactButtonScale,
}) => {
  const { scroll, el } = useScroll();
  const { showContactPage, setShowContactPage } = useContext(Section8Context);
  const { setShowContactUsModal } = useContext(ContactFormContext);

  const handlePointerOver = () => {
    document.body.style.cursor = "pointer";
  };

  const handlePointerOut = () => {
    document.body.style.cursor = "auto";
  };

  useFrame(() => {
    const currentScroll = scroll.current * 100;

    if (showContactPage) {
      if (scroll.current < 0.815) {
        scroll.current += 0.1 / 100;
      }
      setScrollTopToPercentage(81.5, el);
      setTimeout(() => {
        setShowContactPage(false);
      }, 1000);
    }

    // if (currentScroll >= 19 && currentScroll <= 20.7) {
    //   scroll.current = 20.6 / 100;
    //   if (currentScroll > 20.3 && currentScroll < 20.7) {
    //     // setScrollTopToPercentage(20.6, el);
    //   }
    // }

    // // if (showContactPage) {
    // //   if (scroll.current <= 0.97 && currentScroll > 20.6) {
    // //     scroll.current += 0.1 / 100;
    // //     // setScrollTopToPercentage(99, el);
    // //   }
    // //   if (currentScroll > 0 && currentScroll <= 20.6) {
    // //     scroll.current += 0.1 / 100;
    // //     // setScrollTopToPercentage(20.6, el);
    // //   }
    // //   setTimeout(() => {
    // //     setShowContactPage(false);
    // //   }, 1000);
    // // }

    // if (currentScroll > 21.05 && currentScroll < 26) {
    //   scroll.current -= 0.04 / 100;
    //   if (currentScroll > 21.05 && currentScroll < 21.5) {
    //     // setScrollTopToPercentage(21.06, el);
    //   }
    // }

    // if (currentScroll > 20.6 && currentScroll < 21) {
    //   // setScrollTopToPercentage(20.6, el);
    // }

    // prevScrollRef.current = scroll.current;
    // if (
    //   currentScroll >= 20 &&
    //   !isPacketInDestination &&
    //   scroll.current < 0.3 &&
    //   direction != "top" &&
    //   !showContactPage
    // ) {
    //   scroll.current = 0.206;
    //   prevScrollRef.current = 20.5;
    //   // setScrollTopToPercentage(20.6, el);
    //   if (enableScroll && !isPacketInDestination) setEnableScroll(false);
    // }
  });

  return (
    <group position={boxPosition}>
      <PngLoader
        transparent={true}
        renderOrder={-10}
        path="assets/img/Dalai Box With Label.png"
        scale={boxScale}
        zoom={0.5}
      />

      <group
        onClick={() => {
          window.open(
            "https://www.instagram.com/intodalai?igsh=ZmVianBhdzB6MHdt",
            "_blank"
          );
        }}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <PngLoader
          transparent={true}
          renderOrder={-11}
          path="assets/img/instaimage.png"
          position={instaImagePosition}
          scale={instaImageScale}
          zoom={0.5}
        />
      </group>

      <group
        onClick={() => {
          window.open("mailto:info@intodalai.com", "_blank");
        }}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <PngLoader
          transparent={true}
          renderOrder={-11}
          path="assets/img/emailImage.png"
          position={emailImagePosition}
          scale={emailImageScale}
          zoom={0.5}
        />
      </group>

      <group
        onClick={() => {
          window.open("tel:+393441566992", "_blank");
        }}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <PngLoader
          transparent={true}
          renderOrder={-11}
          path="assets/img/vatimage.png"
          position={mobileImagePosition}
          scale={mobileImageScale}
          zoom={0.5}
        />
      </group>

      <group
        onClick={() => {
          setShowContactUsModal(true);
        }}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <PngLoader
          transparent={false}
          renderOrder={-11}
          path="assets/img/contact-us-button.png"
          position={contactButtonPosition}
          scale={contactButtonScale}
          zoom={0.7}
        />
      </group>
    </group>
  );
};

export default Section5Box;
