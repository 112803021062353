import React, { useState, useRef, useEffect, useContext } from "react";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import gsap from "gsap";
import { Section6Context } from "../../context/section6.context";

const durationTime = 0.1; // Transition duration

function ImagePlane({
  texture,
  iconTexture,
  position,
  scale,
  opacity,
  onIconClick,
  clickable,
  onPointerOut,
  onPointerOver,
  lastImage,
}) {
  return (
    <group
      position={position}
      scale={scale}
      onClick={lastImage ? onIconClick : null}
      onPointerOver={lastImage && onPointerOver}
      onPointerOut={onPointerOut}
    >
      <mesh>
        <planeGeometry args={[0.5, 0.7]} />
        <meshBasicMaterial
          map={texture}
          toneMapped={false}
          opacity={opacity}
          transparent
        />
      </mesh>

      {clickable && (
        <mesh
          position={[0, -0.28, 0]}
          scale={[0.3, 0.07, 1]}
          onClick={onIconClick}
          onPointerOver={onPointerOver}
          onPointerOut={onPointerOut}
        >
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial map={iconTexture} transparent opacity={0} />
        </mesh>
      )}
    </group>
  );
}

function ArrowPlane({
  texture,
  position,
  onClick,
  onPointerOver,
  onPointerOut,
}) {
  return (
    <mesh
      position={position}
      onClick={onClick}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
    >
      <planeGeometry args={[0.1, 0.1]} />
      <meshBasicMaterial map={texture} transparent />
    </mesh>
  );
}

function Carousel({
  images,
  imageUrls,
  iconTexturePath,
  scale,
  arrowPosition,
  positionsArr,
  scalesArr,
}) {
  const [startIndex, setStartIndex] = useState(0);
  const [moving, setMoving] = useState(false);
  const groupRef = useRef();
  const { autoRotateCarousel } = useContext(Section6Context);
  const totalImages = images.length;

  const visibleImages = [];
  const visibleUrls = [];

  const prevIndex = (startIndex - 1 + totalImages) % totalImages;
  const nextIndex = (startIndex + 1) % totalImages;

  visibleImages.push(images[prevIndex], images[startIndex], images[nextIndex]);

  visibleUrls.push(
    imageUrls[prevIndex],
    imageUrls[startIndex],
    imageUrls[nextIndex]
  );

  const positions = positionsArr.slice(0, visibleImages.length);
  const scales = scalesArr.slice(0, visibleImages.length);
  const opacities = [0.5, 1, 0.5].slice(0, visibleImages.length);

  // Load textures here using useLoader
  const loadedTextures = useLoader(TextureLoader, images);
  const iconTexture = useLoader(TextureLoader, iconTexturePath);
  const arrowTextureLeft = useLoader(
    TextureLoader,
    "assets/img/Arrow-Left.png"
  );
  const arrowTextureRight = useLoader(
    TextureLoader,
    "assets/img/Arrow-Right.png"
  );

  // States for drag detection
  const [isDragging, setIsDragging] = useState(false);
  const [startMouseX, setStartMouseX] = useState(null);

  const moveCarousel = (left = true) => {
    const nextIndex = left
      ? (startIndex + 1) % totalImages
      : (startIndex - 1 + totalImages) % totalImages;

    const currentImages = groupRef.current.children;

    gsap.to(currentImages, {
      x: left ? -1.5 : 1.5,
      opacity: 0,
      duration: durationTime,
      ease: "power3.inOut",
    });

    setTimeout(() => {
      setStartIndex(nextIndex);
      gsap.set(groupRef.current.children, { opacity: 1, x: 0 });
    }, durationTime * 200);
  };

  const handleNext = () => {
    if (moving) return;
    setMoving(true);
    moveCarousel(true);
    setTimeout(() => setMoving(false), durationTime * 200);
  };

  const handlePrev = () => {
    if (moving) return;
    setMoving(true);
    moveCarousel(false);
    setTimeout(() => setMoving(false), durationTime * 200);
  };

  const handlePointerDown = (event) => {
    setIsDragging(true);
    setStartMouseX(event.clientX);
  };

  const handlePointerMove = (event) => {
    if (!isDragging || moving) return;

    const currentMouseX = event.clientX;
    const dragThreshold = 50;

    if (currentMouseX - startMouseX > dragThreshold) {
      handlePrev();
      setIsDragging(false);
    } else if (startMouseX - currentMouseX > dragThreshold) {
      handleNext();
      setIsDragging(false);
    }
  };

  const handlePointerUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (!autoRotateCarousel) {
      setStartIndex(0);
    }

    const interval = setInterval(() => {
      if (!moving && autoRotateCarousel) {
        handleNext();
      }
    }, 1500);

    return () => clearInterval(interval);
  }, [moving, autoRotateCarousel]);

  const handlePointerOver = () => {
    document.body.style.cursor = "pointer";
  };

  const handlePointerOut = () => {
    document.body.style.cursor = "auto";
  };

  const handleIconClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <group
      ref={groupRef}
      position={[0, -9.2, 4]}
      scale={scale}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
    >
      {visibleImages.map((img, idx) => (
        <ImagePlane
          key={idx}
          texture={loadedTextures[images.indexOf(img)]}
          position={positions[idx]}
          scale={scales[idx]}
          opacity={opacities[idx]}
          onIconClick={() => handleIconClick(visibleUrls[idx])}
          iconTexture={iconTexture}
          onPointerOver={handlePointerOver}
          onPointerOut={handlePointerOut}
          clickable={(startIndex !== 0 && idx === 1) || startIndex === 0}
          lastImage={startIndex === totalImages - 1}
        />
      ))}

      <ArrowPlane
        texture={arrowTextureLeft}
        position={[-arrowPosition[0], arrowPosition[1], 0]}
        onClick={handlePrev}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      />

      <ArrowPlane
        texture={arrowTextureRight}
        position={[arrowPosition[0], arrowPosition[1], 0]}
        onClick={handleNext}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      />
    </group>
  );
}

export default Carousel;
