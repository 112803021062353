import React, { useRef, useEffect, useState, useContext } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { Image as ImageImpl } from "@react-three/drei";
import { LoadingContext } from "../../context/loading.context";
import { TextureLoader } from "three";

const Section2BackgroundImages = ({ width, height, position, startTime }) => {
  const imageRef = useRef();
  const [activeTexture, setActiveTexture] = useState(null);
  const { setLoading } = useContext(LoadingContext);

  // Define the texture paths
  const texturePaths = [
    "assets/img/outdoor-image.png",
    "assets/img/green-house.png",
    "assets/img/indoor-image.png",
  ];

  // Load textures using useLoader
  const loadedTextures = useLoader(TextureLoader, texturePaths);

  useEffect(() => {
    setLoading("section3Images", true);
    setActiveTexture(loadedTextures[startTime] || loadedTextures[0]);
  }, [startTime, loadedTextures, setLoading]);

  useFrame(() => {
    if (imageRef.current) {
      imageRef.current.position.set(...position);
    }
  });

  return (
    <>
      {activeTexture && (
        <ImageImpl
          ref={imageRef}
          texture={activeTexture}
          scale={[width, height, 1]}
          opacity={1}
          transparent={true}
          zoom={0.8}
        />
      )}
    </>
  );
};

export default Section2BackgroundImages;
