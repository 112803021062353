import { useContext, useEffect, useRef, useState } from "react";
import { Section3Context } from "../../context/section3.context";
import { LoadingContext } from "../../context/loading.context";
import { useFrame, useThree } from "@react-three/fiber";
import { useScroll } from "../ScrollControls";
import * as THREE from "three";
import { FontLoader, TextGeometry } from "three/examples/jsm/Addons.js";
import MSDFShader from "../../utils/textGradient";
import { vertex } from "../../shaders/vertex";
import { fragment } from "../../shaders/fragment";
import TextGeometryComponent from "../../utils/textGeometry";

const MovingTextComponent = () => {
  const [generatedTextMesh, setGeneratedTextMesh] = useState(null);
  const [generatedPoints, setGeneratedPoints] = useState(null);
  const [fontTexture, setFontTexture] = useState(null);
  const [font, setFont] = useState(null);
  const [neulisAltFont, setNeulisAltFont] = useState(null);
  const [helvetikerFont, setHelvetikerFont] = useState(null);
  const [textOpacity, setTextOpacity] = useState(0);
  const [particlesCount, setParticlesCount] = useState();
  const { activeMode, showPlayButtons } = useContext(Section3Context);
  const { setLoading } = useContext(LoadingContext);
  const subTextMaterial = useRef(null);
  const bodyTextMaterial = useRef(null);
  const textOpacityInterval = useRef(null);
  const isTextRemoveInProgress = useRef(null);
  const isTextAddInProgress = useRef(null);
  const activeText = useRef(null);
  const { width } = useThree((state) => state.viewport);
  const { scene } = useThree();
  const { scroll } = useScroll();

  useEffect(() => {
    if (textOpacity == 0 || textOpacity == 1) {
      isTextRemoveInProgress.current = false;
    }
  }, [textOpacity]);
  useEffect(() => {
    if (
      !subTextMaterial.current &&
      !bodyTextMaterial.current &&
      !generatedTextMesh &&
      isTextRemoveInProgress.current
    ) {
      isTextRemoveInProgress.current = false;
    }
  }, [subTextMaterial.current, bodyTextMaterial.current, generatedTextMesh]);

  // section 5 details
  const section5TextDetails = useRef({
    isAdded: false,
    text: "\n\nYOUR TRUSTED SUPPLIER",
    align: "center",
    pointSize: 0.9,
    subText: "",
    bodyText: "",
  });

  useEffect(() => {
    if (width < 5.67) {
      setParticlesCount(60);

      // section 5
      section5TextDetails.current.position = [-width * 0.08, 0.25, 4];
      section5TextDetails.current.textScale = [0.0009, -0.0009, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    } else if (width < 7.7 && width >= 5.67) {
      setParticlesCount(80);

      // section 5
      section5TextDetails.current.position = [-width * 0.08, 0.33, 4];
      section5TextDetails.current.textScale = [0.0011, -0.0011, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    } else if (width >= 7.7 && width < 10) {
      setParticlesCount(80);

      // section 5
      section5TextDetails.current.position = [-width * 0.095, -0.4, 4];
      section5TextDetails.current.textScale = [0.000737, -0.000737, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    } else if (width >= 10 && width < 11.34) {
      setParticlesCount(80);

      // section 5
      section5TextDetails.current.position = [-width * 0.095, -0.4, 4];
      section5TextDetails.current.textScale = [0.00106, -0.00108, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    } else if (width >= 11.34 && width < 12.34) {
      setParticlesCount(80);

      // section 5
      section5TextDetails.current.position = [-width * 0.095, -0.3, 4];
      section5TextDetails.current.textScale = [0.00116, -0.00118, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    } else if (width >= 12.34 && width <= 14.5) {
      setParticlesCount(80);

      // section 5
      section5TextDetails.current.position = [-width * 0.095, -0.3, 4];
      section5TextDetails.current.textScale = [0.00125, -0.00125, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    } else {
      setParticlesCount(60);

      // section 5
      section5TextDetails.current.position = [-width * 0.095, -0.3, 4];
      section5TextDetails.current.textScale = [0.0014, -0.0014, 0.01];
      section5TextDetails.current.subTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.bodyTextPosition = [-width * 0.094, 0, 4];
      section5TextDetails.current.subTextSize = 6;
      section5TextDetails.current.bodyTextSize = 4;
    }
  }, [width]);

  // for text movement
  useFrame(({ clock }) => {
    if (generatedTextMesh) {
      generatedTextMesh.uniforms.time.value = clock.getElapsedTime();
    }
    if (generatedPoints) {
      generatedPoints.uniforms.time.value = clock.getElapsedTime();
    }
  });

  // to remove individual section text
  const removeSectionText = (sectionName) => {
    const keysToDispose = ["subTextuuid", "uuid", "bodyTextuuid"];
    keysToDispose.forEach(async (element) => {
      const object = await scene.getObjectByProperty(
        "uuid",
        sectionName.current[element]
      );
      if (object) {
        // object.geometry.dispose();
        // object.material.dispose();
        if (object.parent === scene.children[1]) {
          scene.children[1].remove(object); // Remove from specific container
        } else {
          scene.remove(object); // Remove from the scene if not in scene.children[1]
        }
      }
    });
    activeText.current = null;
  };

  useFrame(() => {
    const currentScroll = scroll.current * 100;
    // if (currentScroll < 23 || currentScroll > 96) {
    //   removeAllSectionText();
    // }

    // add section 5 text
    if (width > 7.7) {
      if (currentScroll > 78 && currentScroll < 88) {
        if (
          section5TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
          scene.add(section5TextDetails.current.points);
        }
        if (
          textOpacity == 0 &&
          section5TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          !isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          !section5TextDetails.current.isAdded &&
          currentScroll > 78 &&
          isTextShouldAdd() &&
          !isTextAddInProgress.current
        ) {
          const object = scene.getObjectByProperty(
            "uuid",
            section5TextDetails.current.uuid
          );
          // if (!object) {
          scene.add(section5TextDetails.current.textMesh);
          scene.add(section5TextDetails.current.subTextMesh);
          scene.add(section5TextDetails.current.bodyTextMesh);
          // }
          isTextRemoveInProgress.current = false;
          isTextAddInProgress.current = true;
          section5TextDetails.current.isAdded = true;
          subTextMaterial.current = section5TextDetails.current.subTextMaterial;
          setGeneratedTextMesh(section5TextDetails.current.material);
          bodyTextMaterial.current =
            section5TextDetails.current.bodyTextMaterial;
          increaseTextOpacity();
          scene.add(section5TextDetails.current.points);
          activeText.current = section5TextDetails.current;
        }
      }
    } else {
      if (currentScroll > 78 && currentScroll < 81.8) {
        if (
          section5TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
          scene.add(section5TextDetails.current.points);
        }
        if (
          textOpacity == 0 &&
          section5TextDetails.current.isAdded &&
          !isTextAddInProgress.current &&
          !isTextRemoveInProgress.current
        ) {
          increaseTextOpacity();
        }
        if (
          !section5TextDetails.current.isAdded &&
          currentScroll > 78 &&
          isTextShouldAdd() &&
          !isTextAddInProgress.current
        ) {
          const object = scene.getObjectByProperty(
            "uuid",
            section5TextDetails.current.uuid
          );
          // if (!object) {
          scene.add(section5TextDetails.current.textMesh);
          scene.add(section5TextDetails.current.subTextMesh);
          scene.add(section5TextDetails.current.bodyTextMesh);
          // }
          isTextRemoveInProgress.current = false;
          isTextAddInProgress.current = true;
          section5TextDetails.current.isAdded = true;
          subTextMaterial.current = section5TextDetails.current.subTextMaterial;
          setGeneratedTextMesh(section5TextDetails.current.material);
          bodyTextMaterial.current =
            section5TextDetails.current.bodyTextMaterial;
          increaseTextOpacity();
          scene.add(section5TextDetails.current.points);
          activeText.current = section5TextDetails.current;
        }
      }
    }

    if (width > 7.7) {
      if (
        (currentScroll > 88 || currentScroll < 78) &&
        section5TextDetails.current.isAdded &&
        !isTextRemoveInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section5TextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section5TextDetails);
      }
    } else {
      if (
        (currentScroll > 81.8 || currentScroll < 78) &&
        section5TextDetails.current.isAdded &&
        !isTextRemoveInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section5TextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section5TextDetails);
      }
    }
  });

  // for loading fontTexture
  useEffect(() => {
    const loadFontTexture = async () => {
      try {
        const texture = await new THREE.TextureLoader().loadAsync(
          "/font/manifold.png"
        );
        setLoading("font", true);

        setFontTexture(texture);
        const loader = new FontLoader();

        loader.load("/font/manifold.json", function (font) {
          setFont(font);
          setLoading("section5json", true);
        });

        loader.load("/font/Neulis Alt Light_Light.json", function (font) {
          setNeulisAltFont(font);
          setLoading("section5font", true);
        });

        loader.load(
          "https://threejs.org/examples/fonts/helvetiker_regular.typeface.json",
          function (font) {
            setHelvetikerFont(font);
            setLoading("section5font", true);
          }
        );
      } catch (error) {
        console.error("Error loading font texture:", error);
      }
    };
    loadFontTexture();
  }, []);

  useEffect(() => {
    if (font && neulisAltFont && fontTexture && helvetikerFont) {
      addText(section5TextDetails, helvetikerFont, 0x7888b7);
    }
  }, [fontTexture, font, neulisAltFont, helvetikerFont]);

  // mouse events
  const setupMouseEvents = (materialText, material) => {
    const handleMouseMove = (event) => {
      const mouse = {
        x: event.clientX / window.innerWidth,
        y: event.clientY / window.innerHeight,
      };
      materialText.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
      material.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
    };
    const handleTouchMove = (event) => {
      const mouse = {
        x: event.touches[0].clientX / window.innerWidth,
        y: event.touches[0].clientY / window.innerHeight,
      };
      materialText.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
      material.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
    };
    const canvas = document.querySelector("canvas");
    const handleWindowResize = (event) => {
      materialText.uniforms.viewport.value = new THREE.Vector2(
        canvas.width,
        canvas.height
      );
      material.uniforms.viewport.value = new THREE.Vector2(
        canvas.width,
        canvas.height
      );
    };
    const handleTouchEnd = () => {
      materialText.uniforms.uMouse.value = new THREE.Vector2(0, 0);
      material.uniforms.uMouse.value = new THREE.Vector2(0, 0);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
  };

  useFrame(() => {
    if (
      subTextMaterial.current &&
      bodyTextMaterial.current &&
      generatedTextMesh
    ) {
      if (textOpacity < 1) {
        subTextMaterial.current.transparent = true;
        subTextMaterial.current.depthWrite = false;
        bodyTextMaterial.current.transparent = true;
        bodyTextMaterial.current.depthWrite = false;
      } else {
        subTextMaterial.current.transparent = false;
        subTextMaterial.current.depthWrite = true;
        bodyTextMaterial.current.transparent = false;
        bodyTextMaterial.current.depthWrite = true;
      }
      subTextMaterial.current.opacity = textOpacity;
      subTextMaterial.current.needsUpdate = true;
      bodyTextMaterial.current.opacity = textOpacity;
      bodyTextMaterial.current.needsUpdate = true;
      generatedTextMesh.uniforms.opacity.value = textOpacity;
      if (
        textOpacity == 0 &&
        (subTextMaterial.current.opacity == 0 ||
          generatedTextMesh.uniforms.opacity.value == 0 ||
          subTextMaterial.current.opacity == 0) &&
        !isTextAddInProgress.current
      ) {
        setGeneratedTextMesh(null);
        subTextMaterial.current = null;
        bodyTextMaterial.current = null;
        isTextRemoveInProgress.current = false;
        activeText.current.isAdded = false;
        removeSectionText(activeText);
        // removeSectionText(activeText);
      }
    }
  });

  // addText
  const addText = (textDetails, fontType, color) => {
    isTextRemoveInProgress.current = false;
    const geometry = TextGeometryComponent({
      text: textDetails.current.text,
      font: font,
      align: textDetails.current.align,
      flipY: fontTexture.flipY,
      scene,
    });

    const material = new THREE.RawShaderMaterial(
      MSDFShader({
        map: fontTexture,
        transparent: true,
        color: color,

        side: THREE.DoubleSide,
        glslVersion: THREE.GLSL3,
        depthTest: true,
        depthWrite: true,
        opacity: 0,
        toneMapped: false,
      })
    );

    let layout = geometry.geometry.layout;
    let textMesh = new THREE.Mesh(geometry.bufferGeometry, material);

    textDetails.current.material = material;
    textMesh.scale.set(...textDetails.current.textScale);
    textMesh.position.set(...textDetails.current.position);

    textDetails.current.uuid = textMesh.uuid;

    const textGeometry = new TextGeometry(textDetails?.current?.subText, {
      font: fontType,
      size: textDetails.current.subTextSize,
      height: 4,
    });

    const textMaterial = new THREE.MeshBasicMaterial({
      color: color,
      transparent: true,
      opacity: 0,
      toneMapped: false,
    });

    const textMesh1 = new THREE.Mesh(textGeometry, textMaterial);
    textMesh1.scale.set(0.006, 0.006, 0);
    textMesh1.position.set(...textDetails.current.subTextPosition);
    // subTextMaterial.current = textMaterial;
    textDetails.current.subTextMaterial = textMaterial;
    textDetails.current.subTextuuid = textMesh1.uuid;
    textDetails.current.subTextMesh = textMesh1;

    const textGeometry2 = new TextGeometry(textDetails.current.bodyText, {
      font: fontType,
      size: textDetails.current.bodyTextSize,
      height: 4,
    });

    const textMaterial2 = new THREE.MeshBasicMaterial({
      color: color,
      transparent: true,
      opacity: 0,
      toneMapped: false,
    });
    const textMesh2 = new THREE.Mesh(textGeometry2, textMaterial2);
    textMesh2.scale.set(0.006, 0.006, 0);
    textMesh2.position.set(...textDetails.current.bodyTextPosition);

    // if (
    //   width < 7.7 &&
    //   (section2TextDetails.current.isAdded ||
    //     section3TextDetails.current.isAdded ||
    //     section4TextDetails.current.isAdded)
    // ) {
    //   scene.children[1].add(textMesh);
    //   scene.children[1].add(textMesh1);
    //   scene.children[1].add(textMesh2);
    // } else {
    //   scene.add(textMesh);
    //   scene.add(textMesh1);
    //   scene.add(textMesh2);
    // }

    // increaseTextOpacity();
    // bodyTextMaterial.current = textMaterial2;
    textDetails.current.bodyTextMaterial = textMaterial2;
    textDetails.current.bodyTextuuid = textMesh2.uuid;
    textDetails.current.textMesh = textMesh;
    textDetails.current.bodyTextMesh = textMesh2;

    // setGeneratedTextMesh(textMesh);
    setupMouseEvents(textMesh.material, material);
    addObjects(layout, textMesh, textDetails, color);
  };

  // addObjects
  const addObjects = (layout, textMesh, textDetails, color) => {
    const textBoundingBox = new THREE.Box3().setFromObject(textMesh);

    let number = textDetails?.current.particlesCount
      ? textDetails?.current.particlesCount
      : particlesCount;
    let geo = new THREE.BufferGeometry();
    let pos = [];

    for (let i = 0; i < number; i++) {
      // Generate random positions within the bounding box of the text mesh
      let x = THREE.MathUtils.randFloat(
        textBoundingBox.min.x,
        textBoundingBox.max.x
      );
      let y = THREE.MathUtils.randFloat(
        textBoundingBox.min.y,
        textBoundingBox.max.y
      );
      let z = THREE.MathUtils.randFloat(
        textBoundingBox.min.z,
        textBoundingBox.max.z
      );

      pos.push(x, y, z);
    }

    pos = new Float32Array(pos);
    geo.setAttribute("position", new THREE.BufferAttribute(pos, 3));

    const material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        viewport: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
        pointSize: { value: textDetails.current.pointSize },
        uMouse: { value: new THREE.Vector2(0, 0) },
        resolution: { value: new THREE.Vector4() },
        uColor: { value: new THREE.Color(color) },
      },
      depthTest: true,
      depthWrite: true,
      transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    const points = new THREE.Points(geo, material);
    // setGeneratedPoints(points);
    textDetails.current.points = points;
    // scene.add(points);
    textDetails.current.uuid2 = points.uuid;
    addTimer(points);
    setupMouseEvents(textMesh.material, material);
  };

  // movement of particles
  const addTimer = (mesh) => {
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const areaBounds = {
      minX: boundingBox.min.x,
      maxX: boundingBox.max.x,
      minY: boundingBox.min.y,
      maxY: boundingBox.max.y,
      minZ: boundingBox.min.z,
      maxZ: boundingBox.max.z,
    };
    const maxVelocity = 0.05; // Maximum speed for particles
    const particlesCount = mesh.geometry.attributes.position.count;
    const velocities = new Array(particlesCount * 3)
      .fill(0)
      .map(() => (Math.random() - 0.5) * maxVelocity);

    setInterval(() => {
      const positions = mesh.geometry.attributes.position.array;

      for (let i = 0; i < positions.length; i++) {
        positions[i] += velocities[i];

        // Check and adjust position to stay within bounds
        if (i % 3 === 0) {
          // X-axis
          if (
            positions[i] < areaBounds.minX ||
            positions[i] > areaBounds.maxX
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minX,
            Math.min(areaBounds.maxX, positions[i])
          );
        } else if (i % 3 === 1) {
          // Y-axis
          if (
            positions[i] < areaBounds.minY ||
            positions[i] > areaBounds.maxY
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minY,
            Math.min(areaBounds.maxY, positions[i])
          );
        } else {
          // Z-axis
          if (
            positions[i] < areaBounds.minZ ||
            positions[i] > areaBounds.maxZ
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minZ,
            Math.min(areaBounds.maxZ, positions[i])
          );
        }
      }
      // Update the buffer geometry to reflect changes
      mesh.geometry.attributes.position.needsUpdate = true;
    }, 100);
  };

  const isTextShouldAdd = () => {
    return (
      !generatedTextMesh &&
      !subTextMaterial.current &&
      !bodyTextMaterial.current
    );
  };

  const increaseTextOpacity = () => {
    clearInterval(textOpacityInterval.current);
    textOpacityInterval.current = null;
    isTextRemoveInProgress.current = false;
    const fadeIn = () => {
      setTextOpacity((prevOpacity) => {
        const newValue = Math.min(prevOpacity + 0.05, 1);
        if (newValue > 0.98) {
          clearInterval(textOpacityInterval.current);
          textOpacityInterval.current = null;
          isTextRemoveInProgress.current = false;
          isTextAddInProgress.current = false;
        }
        return newValue;
      });
    };

    textOpacityInterval.current = setInterval(() => {
      fadeIn();
    }, 100); // Change the interval as per your requirement
  };

  const removeTextCheckFunction = (textDetails) => {
    // if (isTextShouldRemove()) removeSectionText(textDetails);
    if (!isTextRemoveInProgress.current) {
      isTextRemoveInProgress.current = true;
      decreaseTextOpacity(textDetails);
    }
  };

  const decreaseTextOpacity = (textDetails) => {
    isTextRemoveInProgress.current = true;
    isTextAddInProgress.current = false;
    clearInterval(textOpacityInterval.current);
    textOpacityInterval.current = null;
    const fadeOut = () => {
      setTextOpacity((prevOpacity) => {
        const newValue = Math.max(prevOpacity - 0.15, 0);
        if (newValue == 0) {
          textDetails.current.isAdded = false;
          isTextRemoveInProgress.current = false;
          clearInterval(textOpacityInterval.current);
          textOpacityInterval.current = null;
        }
        return newValue;
      });
    };

    textOpacityInterval.current = setInterval(() => {
      fadeOut();
    }, 50);
  };

  return <></>;
};
export default MovingTextComponent;
