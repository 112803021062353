import React, { useContext } from "react";
import { LoadingContext } from "../context/loading.context";
import Logo from "./logo.component";

const LoadingPage = () => {
  const { isAllLoaded, currentProgress } = useContext(LoadingContext);

  return (
    <>
      {!isAllLoaded && (
        <>
          <div className="flex flex-col  bg-gradient-to-b from-[#041934] to-[#7888B7] z-10 justify-center items-center h-screen w-screen absolute top-0 left-0 ">
            <img
              className="-mt-20"
              src="assets/img/dalai logo 1px.svg"
              alt="Logo"
              style={{ width: "100%", height: "100%", zIndex: 10 }}
            />
          </div>

          <div className="  flex flex-col z-20 justify-end items-center h-[95dvh] w-screen absolute top-0 left-0">
            <div className="text-white text-center mb-5 sm:mb-8 !text-xl md:text-lg  ">
              {" "}
              Loading{" "}
            </div>

            <div className="max-w-[600px] mb-5 sm:mb-8 w-full mx-auto">
              <div className="h-[6px] w-full bg-[#666465] rounded-[20px] relative max-w-[60%] mx-auto">
                <div
                  className={`h-[8px]  bg-white absolute left-0  top-1/2 rounded-[20px] -translate-y-1/2  `}
                  style={{ width: `${currentProgress}%` }}
                ></div>
              </div>
            </div>

            <div className="font-extrabold !text-2xl md:text-5xl  text-white mb-2 md:mb-5 ">
              {" "}
              {currentProgress}%{" "}
            </div>
          </div>
        </>
      )}
      {isAllLoaded && (
        <>
          <Logo />
        </>
      )}
    </>
  );
};

export default LoadingPage;
