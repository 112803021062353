import { useEffect, useRef, useState } from "react";
import PacketCarousel3D from "./packetCarousel.component";
import { useFrame, useThree } from "@react-three/fiber";
import { useScroll } from "../ScrollControls";
import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";

const Section4 = () => {
  const { width } = useThree((state) => state.viewport);
  // for packets
  const [packetRotation, setPacketRotation] = useState();
  const [packetPosition, setPacketPosition] = useState();
  const [packetScale, setPacketScale] = useState();
  const [packetYRotation, setPacketYRotation] = useState();
  const [packetrightArrowPosition, setPacketRightArrowPosition] = useState();
  const [packetleftArrowPosition, setPacketLeftArrowPosition] = useState();
  const [arrowScale, setArrowScale] = useState();
  const [circleRadius, setCircleRadius] = useState();
  const [direction, setDirection] = useState();
  const [isPacketInDestination, setIsPacketInDestination] = useState(false);
  const [isPacketInitialPosition, setIsPacketInitialPosition] = useState(false);
  const [carouselGroupPosition, setCarouselGroupPosition] = useState();
  const isInDestination = useRef(false);
  const { scroll, el } = useScroll();

  useEffect(() => {
    if (width < 5.67) {
      setPacketPosition([0.4, -4.2]);
      setPacketRotation(-0.01);
      setPacketYRotation(0.07);
      setCircleRadius(0.17);
      setArrowScale([0.27, 0.27, 1]);
      setPacketLeftArrowPosition([-0.1, -3.15, 2]);
      setPacketRightArrowPosition([1, -3.15, 2]);
      setPacketScale([0.03, 0.032, 0.00001]);
      setPacketLeftArrowPosition([0.1, -3.35, 2]);
      setPacketRightArrowPosition([0.55, -3.35, 2]);

      setArrowScale([0.23, 0.23, 1]);
      setCarouselGroupPosition([-0.4, -7.2, 0.1]);
    } else if (width < 7.7 && width >= 5.67) {
      setPacketPosition([0.4, -4.2]);
      setPacketRotation(-0.01);
      setPacketYRotation(0.05);
      setCircleRadius(0.27);
      setArrowScale([0.27, 0.27, 1]);
      setPacketLeftArrowPosition([-0.1, -3.15, 2]);
      setPacketRightArrowPosition([1, -3.15, 2]);
      setPacketScale([0.04, 0.04, 0.00001]);
      setPacketLeftArrowPosition([-0, -3.17, 2]);
      setPacketRightArrowPosition([0.65, -3.17, 2]);
      setArrowScale([0.27, 0.27, 1]);
      setCarouselGroupPosition([-0.4, -7.2, 0.1]);
    } else if (width >= 7.7 && width < 11.34) {
      setPacketPosition([0.45, -4.2]);
      setPacketRotation(-0.385);
      setPacketYRotation(0.4);
      setCircleRadius(0.3);
      setArrowScale([0.27, 0.27, 1]);
      setPacketLeftArrowPosition([-0.1, -3.15, 2]);
      setPacketRightArrowPosition([1, -3.15, 2]);
      setPacketScale([0.045, 0.046, 0.00001]);
      setPacketLeftArrowPosition([-0, -3.17, 2]);
      setPacketRightArrowPosition([0.84, -3.17, 2]);
      setArrowScale([0.27, 0.27, 1]);
      setCarouselGroupPosition([-0.1, -7.1, 0.1]);
    } else if (width >= 11.34 && width <= 14.5) {
      setPacketPosition([0.45, -4.2]);
      setPacketRotation(-0.385);
      setPacketYRotation(0.4);
      setCircleRadius(0.38);
      setArrowScale([0.27, 0.27, 1]);
      setPacketLeftArrowPosition([-0.1, -3.15, 2]);
      setPacketRightArrowPosition([1, -3.15, 2]);
      setPacketScale([0.05, 0.051, 0.00001]);
      setPacketLeftArrowPosition([-0.03, -3.2, 2]);
      setPacketRightArrowPosition([0.88, -3.2, 2]);
      setArrowScale([0.27, 0.27, 1]);
      setCarouselGroupPosition([-0.1, -7.05, 0.1]);
    } else {
      setPacketPosition([0.45, -4.2]);
      setPacketRotation(-0.37);
      setPacketYRotation(0.4);
      setCircleRadius(0.38);
      setArrowScale([0.27, 0.27, 1]);
      setPacketLeftArrowPosition([-0.1, -3.15, 2]);
      setPacketRightArrowPosition([1, -3.15, 2]);
      setPacketScale([0.055, 0.056, 0.00001]);
      setPacketLeftArrowPosition([-0.1, -3.13, 2]);
      setPacketRightArrowPosition([1, -3.13, 2]);
      setArrowScale([0.27, 0.27, 1]);
      setCarouselGroupPosition([-0.1, -7.05, 0.1]);
    }
  }, [width]);

  useFrame(() => {
    const currentScroll = scroll.current * 100;

    // section 5 entry
    if (currentScroll >= 79 && currentScroll < 81.5) {
      scroll.current += 0.18 / 100;
      if (currentScroll > 81.2 && currentScroll < 81.5) {
        setScrollTopToPercentage(81.5, el);
      }
    }
  });

  return (
    <>
      <group position={carouselGroupPosition}>
        {packetPosition &&
          packetRotation &&
          packetScale &&
          circleRadius &&
          packetYRotation && (
            <PacketCarousel3D
              direction={direction}
              setDirection={setDirection}
              isPacketInitialPosition={isPacketInitialPosition}
              setIsPacketInDestination={setIsPacketInDestination}
              isPacketInDestination={isPacketInDestination}
              setIsPacketInitialPosition={setIsPacketInitialPosition}
              isInDestination={isInDestination}
              position={[packetPosition[0], packetPosition[1], 4.1]}
              packetRotation={packetRotation}
              packetScale={packetScale}
              radius={circleRadius}
              packetYRotation={packetYRotation}
              arrowScale={arrowScale}
              packetleftArrowPosition={packetleftArrowPosition}
              packetrightArrowPosition={packetrightArrowPosition}
            />
          )}
      </group>
    </>
  );
};

export default Section4;
