import { useEffect, useRef, useState } from "react";
import Section5Box from "./boxWingModel.component";
import { useFrame, useThree } from "@react-three/fiber";
import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";
import { useScroll } from "../ScrollControls";
import PngLoader from "../pngLoader.component";
import MovingTextComponent from "./movingText.component";

const Section5 = () => {
  // for box
  const [boxScale, setBoxScale] = useState();
  const [boxPosition, setBoxPosition] = useState();
  const [emailImagePosition, setEmailImagePosition] = useState();
  const [instaImagePosition, setInstaImagePosition] = useState();
  const [mobileImagePosition, setMobileImagePosition] = useState();
  const [emailImageScale, setEmailImageScale] = useState();
  const [instaImageScale, setInstaImageScale] = useState();
  const [mobileImageScale, setMobileImageScale] = useState();
  const [contactButtonScale, setContactButtonScale] = useState();
  const [contactButtonPosition, setContactButtonPosition] = useState();
  const [direction, setDirection] = useState();
  const [isPacketInDestination, setIsPacketInDestination] = useState(false);
  const [isPacketInitialPosition, setIsPacketInitialPosition] = useState(false);
  const [downArrowPosition, setDownArrowPosition] = useState();
  const [downArrowScale, setDownArrowScale] = useState();

  const { width } = useThree((state) => state.viewport);
  const { scroll, el } = useScroll();

  var userAgent = navigator.userAgent;
  const prevScrollRef = useRef(null);

  useEffect(() => {
    if (width < 5.67) {
      setBoxScale([0.6, 0.6, 0.1]);
      setBoxPosition([0, -5.68, 4.5]);
      setEmailImagePosition([0, -0.007, 0]);
      setInstaImagePosition([0.001, -0.03, 0]);
      setMobileImagePosition([0.02, 0.022, 0]);
      setEmailImageScale([0.31, 0.1, 1]);
      setInstaImageScale([0.32, 0.12, 1]);
      setMobileImageScale([0.28, 0.13, 1]);
      setDownArrowPosition([0, -4.85, 4.1]);
      setDownArrowScale([0.2, 0.218, 0.1]);
      setContactButtonPosition([-0.066, -0.046, 0]);
      setContactButtonScale([0.25, 0.13, 1]);
    } else if (width < 7.7 && width >= 5.67) {
      setBoxScale([0.72, 0.74, 0.1]);
      setBoxPosition([0, -5.68, 4.5]);
      setEmailImagePosition([0, -0.007, 0]);
      setInstaImagePosition([0.001, -0.04, 0]);
      setMobileImagePosition([0.026, 0.022, 0]);
      setEmailImageScale([0.35, 0.17, 1]);
      setInstaImageScale([0.37, 0.17, 1]);
      setMobileImageScale([0.32, 0.17, 1]);
      setDownArrowPosition([0, -4.85, 4.1]);
      setDownArrowScale([0.2, 0.215, 0.1]);
      setContactButtonPosition([-0.086, -0.065, 0]);
      setContactButtonScale([0.29, 0.15, 1]);
    } else if (width >= 7.7 && width < 11.34) {
      setBoxScale([0.75, 0.75, 0.1]);
      setBoxPosition([width * 0.015, -5.63, 4.5]);
      setEmailImagePosition([-0.01, -0.01, 0]);
      setInstaImagePosition([0, -0.05, 0]);
      setMobileImagePosition([0.03, 0.034, 0]);
      setEmailImageScale([0.37, 0.17, 1]);
      setInstaImageScale([0.4, 0.17, 1]);
      setMobileImageScale([0.35, 0.17, 1]);
      setDownArrowPosition([0, -4.672, 4.1]);
      setDownArrowScale([0.24, 0.26, 0.1]);
      setContactButtonPosition([-0.11, -0.077, 0]);
      setContactButtonScale([0.315, 0.15, 1]);
    } else if (width >= 11.34 && width <= 14.5) {
      setBoxScale([0.86, 0.86, 0.1]);
      setBoxPosition([0.2, -5.6, 4.5]);
      setEmailImagePosition([-0.01, -0.01, 0]);
      setInstaImagePosition([0, -0.057, 0]);
      setMobileImagePosition([0.037, 0.036, 0]);
      setEmailImageScale([0.4, 0.17, 1]);
      setInstaImageScale([0.42, 0.17, 1]);
      setMobileImageScale([0.37, 0.17, 1]);
      setDownArrowPosition([0, -4.685, 4.1]);
      setDownArrowScale([0.22, 0.25, 0.1]);
      setContactButtonPosition([-0.12, -0.09, 0]);
      setContactButtonScale([0.36, 0.17, 1]);
    } else {
      setBoxScale([0.9, 0.9, 0.1]);
      setBoxPosition([0.2, -5.6, 4.5]);
      setEmailImagePosition([0, -0.02, 0]);
      setInstaImagePosition([0, -0.07, 0]);
      setMobileImagePosition([0.045, 0.04, 0]);
      setEmailImageScale([0.49, 0.17, 1]);
      setInstaImageScale([0.49, 0.17, 1]);
      setMobileImageScale([0.42, 0.17, 1]);
      setContactButtonPosition([-0.135, -0.105, 0]);
      setContactButtonScale([0.35, 0.17, 1]);
      setDownArrowPosition([0, -4.72, 4.1]);
      setDownArrowScale([0.22, 0.25, 0.1]);
    }
  }, [width]);

  useFrame(() => {
    const currentScroll = scroll.current * 100;

    if (currentScroll > prevScrollRef.current) {
      setDirection("down");
    } else {
      setDirection("top");
    }

    // section 5 exit
    if (currentScroll >= 82.5 && currentScroll < 87.5) {
      scroll.current -= 0.18 / 100;
    }
    if (currentScroll > 82.2 && currentScroll < 82.5) {
      setScrollTopToPercentage(82.5, el);
    }

    // section 6 entry
    if (currentScroll >= 87.5 && currentScroll < 98.9) {
      scroll.current += 0.18 / 100;
      if (currentScroll > 98.6 && currentScroll < 98.9) {
        setScrollTopToPercentage(98.9, el);
      }
    }

    if (
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i)
      //  ||
      // userAgent.match(/Android/i)
    ) {
      if (
        currentScroll > 93 &&
        currentScroll < 99.8
        // // currentScroll > prevScrollRef.current
        // !hehe2.current
      ) {
        scroll.current += 0.1 / 100;
        if (currentScroll > 99 && currentScroll < 99.8) {
          setScrollTopToPercentage(99.8, el);
        }
      }
    }

    // if (userAgent.match(/Android/i)) {
    //   if (
    //     currentScroll > 93 &&
    //     currentScroll < 99.56 &&
    //     currentScroll > prevScrollRef.current

    //     // &&
    //     // !hehe2.current
    //   ) {
    //     scroll.current += 0.1 / 100;
    //     if (currentScroll > 99 && currentScroll < 99.56) {
    //       setScrollTopToPercentage(99.56, el);
    //     }
    //   }
    //   if (
    //     currentScroll > 93 &&
    //     currentScroll < 99.89 &&
    //     currentScroll < prevScrollRef.current
    //   ) {
    //     scroll.current -= 0.1 / 100;
    //     if (currentScroll > 93 && currentScroll < 93.8) {
    //       setScrollTopToPercentage(93, el);
    //     }
    //   }
    // }

    if (
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i)
      //  ||
      // userAgent.match(/Android/i)
    ) {
      if (
        currentScroll > 0.1 &&
        currentScroll < 1
        // !hehe.current
      ) {
        scroll.current += 0.1 / 100;
      }
    }

    // if (userAgent.match(/Android/i)) {
    //   if (
    //     currentScroll > 0.2 &&
    //     currentScroll < 1.5 &&
    //     currentScroll > prevScrollRef.current
    //     // &&
    //     // !hehe.current
    //   ) {
    //     scroll.current += 0.1 / 100;
    //   }

    //   if (
    //     currentScroll > 0.2 &&
    //     currentScroll < 1.5 &&
    //     currentScroll < prevScrollRef.current
    //     // hehe.current
    //   ) {
    //     scroll.current -= 0.1 / 100;
    //     if (currentScroll > 0.21 && currentScroll < 0.7) {
    //       setScrollTopToPercentage(0.21, el);
    //     }
    //   }
    // }

    if (currentScroll > 100) {
      scroll.current = 0.99999;
    }

    prevScrollRef.current = currentScroll;
  });

  const handlePointerOver = () => {
    document.body.style.cursor = "pointer";
  };

  const handlePointerOut = () => {
    document.body.style.cursor = "auto";
  };

  const handleClick = () => {
    scroll.current = 0.999;

    // Create and dispatch the touchend event
    const touchEndEvent = new Event("touchend");
    window.dispatchEvent(touchEndEvent);
  };

  return (
    <>
      <MovingTextComponent />
      <group position={[0, -7, 0.1]}>
        <Section5Box
          direction={direction}
          isPacketInDestination={isPacketInDestination}
          boxScale={boxScale}
          boxPosition={boxPosition}
          emailImagePosition={emailImagePosition}
          instaImagePosition={instaImagePosition}
          mobileImagePosition={mobileImagePosition}
          emailImageScale={emailImageScale}
          instaImageScale={instaImageScale}
          mobileImageScale={mobileImageScale}
          contactButtonScale={contactButtonScale}
          contactButtonPosition={contactButtonPosition}
        />
        <group
          onClick={handleClick}
          onPointerOver={handlePointerOver}
          onPointerOut={handlePointerOut}
        >
          <PngLoader
            transparent={true}
            renderOrder={-10}
            path="assets/img/Down-Arrow.png"
            position={downArrowPosition}
            scale={downArrowScale}
          />
        </group>
      </group>
    </>
  );
};

export default Section5;
