import React, { createContext, useState } from "react";

const defaultValues = {
  showContactUsModal: false,
  setShowContactUsModal: () => {},
};
export const ContactFormContext = createContext(defaultValues);

export const ContactFormContextProvider = ({ children }) => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  const value = {
    showContactUsModal,
    setShowContactUsModal,
  };

  return (
    <ContactFormContext.Provider value={value}>
      {children}
    </ContactFormContext.Provider>
  );
};
