import React, { createContext, useCallback, useState } from "react";

const defaultValues = {
  showPlayButtons: true,
  setShowPlayButtons: () => {},
};
export const Section3Context = createContext(defaultValues);

export const Section3ContextProvider = ({ children }) => {
  const [showPlayButtons, setShowPlayButtons] = useState(false);
  const [showExperianceButton, setShowExperianceButton] = useState(false);
  const [activeMode, setActiveMode] = useState("outdoor");
  const [timeRange, setTimeRange] = useState({
    startTime: 0,
    endTime: 1.666,
  });

  const handleHideButtonClick = useCallback(() => {
    setShowPlayButtons(false);
    setActiveMode("outdoor");
    setTimeRange({ startTime: 0, endTime: 11.48 });
  }, []);

  const handleButtonClick = useCallback((newStartTime, newEndTime) => {
    setTimeRange({ startTime: newStartTime, endTime: newEndTime });
  }, []);

  const value = {
    showPlayButtons,
    setShowPlayButtons,
    timeRange,
    setTimeRange,
    showExperianceButton,
    setShowExperianceButton,
    handleHideButtonClick,
    handleButtonClick,
    setActiveMode,
    activeMode,
  };

  return (
    <Section3Context.Provider value={value}>
      {children}
    </Section3Context.Provider>
  );
};
